<template>
  <teleport to="body">
    <transition-group
      :name="$style.fade"
      tag="div"
      :class="$style.toasts"
    >
      <div
        v-for="toast in messages"
        :key="toast.id"
        :class="toastClass(toast)"
      >
        <div :class="$style.toasts__title">
          {{ toast.title }}
        </div>
        <div>
          <router-link
            v-if="toast.action"
            :to="toast.action"
            :class="$style.toasts__action"
          >
            {{ toast.message }}
          </router-link>
          <template v-else>
            {{ toast.message }}
          </template>
        </div>
      </div>
    </transition-group>
  </teleport>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { type ToastMessage, MessageType } from '@/features/auth/types/System';
import { mapState } from 'pinia';
import useSystemStore from '@/features/auth/store/systemStore';

export default defineComponent({
  name: 'Toast',
  computed: {
    ...mapState(useSystemStore, ['messages']),
  },
  methods: {
    toastClass(toast: ToastMessage) {
      let style;
      switch (toast.type) {
        case MessageType.Error:
          style = 'toasts__message-error';
          break;
        case MessageType.Warning:
          style = 'toasts__message-warning';
          break;
        case MessageType.Success:
          style = 'toasts__message-success';
          break;
      }
      return ['toasts__message', style].map((style) => this.$style[style]);
    },
  },
});
</script>

<style module lang="scss">
.toasts {
  position: fixed;
  z-index: 9999;
  bottom: 20px;
  right: 20px;
  transition: height 2s ease;

  &__message {
    color: utils.ds-get-color(uptake-reverse-text-color);
    padding: 10px 15px;
    min-width: 320px;
    border-radius: vars.$border-radius;
    margin-top: 5px;

    &-error {
      background-color: utils.ds-get-color(uptake-medium-accent);
    }

    &-warning {
      background-color: utils.ds-get-color(uptake-tertiary-background);
    }

    &-success {
      background-color: utils.ds-get-color(uptake-medium-accent);
    }
  }
  &__title {
    display: block;
    font-weight: bold;
  }

  &__action {
    color: utils.ds-get-color(uptake-reverse-text-color);
    margin-top: 25px;
  }
}
.fade {
  &:global(-enter-active),
  &:global(-leave-active) {
    transition: opacity 0.5s;
  }
  &:global(-enter-from),
  &:global(-leave-to) {
    opacity: 0;
  }
}
</style>
