<template>
  <div :class="$style['admin-shield']">
    <ShieldIcon :class="$style['admin-shield-icon']" />

    <div :class="$style['admin-shield-menu']">
      <router-link to="/admin/UserManagement">
        {{ t('adminTabs.user') }}
      </router-link>
      <router-link
        v-if="manageConnectorsDeployFlagEnabled"
        to="/admin/ManageConnectors"
      >
        {{ t('adminTabs.connector') }}
      </router-link>
      <router-link
        v-if="!manageInsightsDeployFlagEnabled && casesFeatureEnabled && product === 'commercial'"
        to="/admin/AutoCaseConfig"
      >
        {{ t('adminTabs.autoCaseCreate') }}
      </router-link>
      <router-link
        v-if="manageInsightsDeployFlagEnabled && product === 'commercial'"
        to="/admin/ManageInsights"
      >
        {{ t('adminTabs.insight') }}
      </router-link>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import useTenantStore from '@/features/auth/tenant/store/tenantStore';

const { t } = useI18n();

const tenantStore = useTenantStore();
const product = computed(() => tenantStore.config.product);

const casesFeatureEnabled = computed(() => tenantStore.casesFeatureEnabled === true);
const manageConnectorsDeployFlagEnabled = computed(() => tenantStore.manageConnectorsDeployFlagEnabled === true);
const manageInsightsDeployFlagEnabled = computed(() => tenantStore.manageInsightsDeployFlagEnabled === true);
</script>

<style module lang="scss">
.admin-shield {
  position: relative;

  &-icon {
    padding: 0px 15px;
    width: 25px;
    height: 25px;
  }
  &-menu {
    position: absolute;
    display: none;
    top: 140%;
    background-color: utils.ds-get-color(uptake-dark-page-background);
    color: utils.ds-get-color(uptake-reverse-text-color);
    padding: 0 10px 10px 10px;
    z-index: 1003;
    border-radius: 4px;
    padding: 5px 10px;
    width: 135px;

    a {
      text-decoration: none;
      color: utils.ds-get-color(uptake-reverse-text-color) !important;
      display: block;

      &:hover {
        color: utils.ds-get-color(uptake-reverse-text-color) !important;
        text-decoration: underline;
      }

      &:not(:last-child) {
        margin-bottom: 10px;
      }
    }

    &:before {
      content: '';
      border: 15px solid transparent;
      border-bottom: 15px solid utils.ds-get-color(uptake-dark-page-background);
      top: -24px;
      position: absolute;
    }
  }

  &:hover &-menu {
    display: block;
  }
}
</style>
